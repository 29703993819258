import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { ERROR_CODES } from '@common/utils';

import { AUTH_MANAGER, AUTH_NAVIGATOR, AuthManager, AuthNavigator } from '@portal/shared/utils';

export const tenantNexusAuthInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authManager: AuthManager = inject(AUTH_MANAGER);

    if (!authManager.hasJwt()) {
        return next(req);
    }

    const authNavigator: AuthNavigator = inject(AUTH_NAVIGATOR);

    return next(
        req.clone({
            // headers: request.headers.append
            setHeaders: { Authorization: `Bearer ${authManager.getJwt()!.accessToken}` },
        })
    ).pipe(catchError((err: unknown) => (err instanceof HttpErrorResponse ? handleHttpError(err) : throwError(() => err))));

    function handleHttpError(err: HttpErrorResponse): Observable<any> {
        if (err.status === ERROR_CODES.UNAUTHORIZED) {
            authManager.resetJwt();
            void authNavigator.logoutNavigate();
        }
        if (err.status === ERROR_CODES.FORBIDDEN) {
            authManager.resetJwt();
            void authNavigator.logoutNavigate();
        }
        if (err.status === ERROR_CODES.SERVER_ERROR) {
            authManager.resetJwt();
            void authNavigator.logoutNavigate();
        }
        return throwError(() => err);
    }
};
