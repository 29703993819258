import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export class TenantAdminResource {
    private readonly api: HttpClient = inject(HttpClient);

    public removeTenant(tenantId: string): Observable<void> {
        return this.api.delete<void>(`tenant/v1/admin/${tenantId}`);
    }

    public resetTenant(tenantId: string): Observable<void> {
        return this.api.put<void>(`tenant/v1/admin/reset/${tenantId}`, null);
    }
}
