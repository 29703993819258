import { Injectable } from '@angular/core';

import { MessageBus, MessageBusEvent } from '@portal/core/utils';

export enum AuthTenantNexusEventType {
    LOGOUT = '[Auth Tenant Nexus]  Logout',
    LOGOUT_SUCCESS = '[Auth Tenant Nexus] Logout Success',

    LOGIN = '[Auth Tenant Nexus] Login',
    LOGIN_SUCCESS = '[Auth Tenant Nexus] Login Success',
}

export namespace fromAuthTenantNexusEvents {
    export class Login implements MessageBusEvent {
        public type: AuthTenantNexusEventType.LOGIN = AuthTenantNexusEventType.LOGIN;
    }

    export class LoginSuccess implements MessageBusEvent {
        public type: AuthTenantNexusEventType.LOGIN_SUCCESS = AuthTenantNexusEventType.LOGIN_SUCCESS;

        constructor(public payload: { token: string }) {}
    }

    export class Logout implements MessageBusEvent {
        public type: AuthTenantNexusEventType.LOGOUT = AuthTenantNexusEventType.LOGOUT;
    }

    export class LogoutSuccess implements MessageBusEvent {
        public type: AuthTenantNexusEventType.LOGOUT_SUCCESS = AuthTenantNexusEventType.LOGOUT_SUCCESS;
    }
}

export type AuthTenantNexusEventTypes =
    | fromAuthTenantNexusEvents.Login
    | fromAuthTenantNexusEvents.LoginSuccess
    | fromAuthTenantNexusEvents.Logout
    | fromAuthTenantNexusEvents.LogoutSuccess;

@Injectable()
export class AuthTenantNexusMessageBus extends MessageBus<AuthTenantNexusEventTypes, AuthTenantNexusEventType> {}
