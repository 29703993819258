export enum TenantState {
    IN_PROGRESS = 'IN_PROGRESS',
    READY = 'READY',
    FILED_TO_MIGRATE = 'FILED_TO_MIGRATE',
    FAILED_TO_CREATE = 'FAILED_TO_CREATE',
    FILED_TO_RESET = 'FILED_TO_RESET',
}

export interface TenantRegistryDto {
    tenantId: string;
    state: TenantState;
    created: number;
    updated: number;
    info: string;
}
