import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { LOCAL_STORAGE } from '@common/utils';

import { AuthManager, JwtResponseDto, LocalStorageService } from '@portal/shared/utils';

@Injectable({ providedIn: 'root' })
export class TenantNexusAuthService implements AuthManager {
    readonly #tokenKey: string = 'authTenantNexusToken';

    readonly #localeStorageService = inject(LocalStorageService);
    readonly #localStorage = inject(LOCAL_STORAGE);

    readonly #tokenSource = new BehaviorSubject<JwtResponseDto | null>(this.#localStorage.getItem<JwtResponseDto>(this.#tokenKey));

    constructor() {
        this.#tokenSource.pipe(takeUntilDestroyed()).subscribe((token: JwtResponseDto | null) => {
            if (token === null) {
                this.#localStorage.removeItem(this.#tokenKey);
            } else {
                this.#localStorage.setItem<JwtResponseDto | null>(this.#tokenKey, token);
            }
        });
    }

    public hasJwt(): boolean {
        return this.#tokenSource.value !== null;
    }

    public getJwt(): JwtResponseDto | null {
        return this.#tokenSource.value;
    }

    public setJwt(jwt: JwtResponseDto): void {
        this.#tokenSource.next(jwt);
    }

    public initializeJwt(): Observable<JwtResponseDto | null> {
        return this.#localeStorageService.receive<JwtResponseDto | null>(this.#tokenKey);
    }

    public isAuthenticated(): Observable<boolean> {
        return this.#tokenSource.pipe(map((jwt: JwtResponseDto | null) => jwt !== null));
    }

    public resetJwt(): void {
        this.#tokenSource.next(null);
    }
}
